import React from 'react';


export default class FormError extends React.Component
{
    // MARK: - Render
    render()
    {
		return (
		<>
			<p>{this.props.error}</p>
		</>);
    }
}
