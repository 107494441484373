import ConfirmReset  from './ConfirmReset';
import FormLoader    from '../../components/Form/FormLoader';
import InitiateReset from './InitiateReset';
import QueryString 	 from 'query-string';
import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import { withRouter } from 'react-router-dom';


/**
  *  State:
  *  	@param 	{bool} 		isLoading		Form loading or not
  */
class Reset extends React.Component
{
	// MARK: - Constructor
	constructor(props)
	{
		console.log("Reset()");
		super(props);

		this.state =
		{
			isLoading: false
		}
	}
	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
    {
        return (this.state.isLoading !== nextState.isLoading);
    }

	render()
	{
		console.log('Reset.render()');
		let qs = QueryString.parse(this.props.location.search);
		return (
		<>
			{this.props.cookies.get('user') ? <Redirect to='/home' /> : ''}
			<FormLoader
				isLoading={this.state.isLoading}
			/>
			{(qs.token && qs.user) ? <ConfirmReset qs={qs} showAlert={this.props.showAlert} /> : <InitiateReset showAlert={this.props.showAlert} />}
		</>
		);
	}
}
export default withRouter(Reset);
