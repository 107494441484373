import React from 'react';
import FormError from './FormError';
import FormInput from './FormInput';


/**
  * Form that has validation on fields
  *
  *	@param 	{function} 	formOnChange 	Callback when a form value changes
  * @param  {bool}        showErrorList   Flag to show error list on top of form or not
  *	@param	[{JSON}]	formInputs 	   Array of JSON objects representing form inputs
  * 			     label: 		Label text to show above input
  * 			     id:			ID of inputs so it can be accessed by parent later
  * 			     type: 		    Type of input (text/password/email)
  *                  class:         Class names
  * 			     element: 	  The element type (input/textarea)
  * 			     placeholder: The placeholder text {default=""}
  *         	     value:       Value
  *         	     onClick:     Onclick javascript handler
  *				     required: 	 Required to submit form {default=false}
  *         	     validation 	 JSON object with validation algorithm return true/false
  *				         algorithm: email|range
  *                     requirement: text that shows when requirement is not met
  *				         params: [range: min,max]
  *
  *	State:
  *		@param	{bool} 	    isFormValid		If form is valid to submit according to validation rules on each field
  *     @param  {Map}         formErrors      Key=>ID Value=>ErrorMessage holds all form errors
  *
  */
 export default class FormValidated extends React.Component
 {
     // MARK: - Data fields
     submitBtn      = null;
     formErrors     = [];    // Error components created from state data
     fieldRefs      = new Map();   // Key=>ID Value=Reference to child input

	 // MARK: - Constructor
     constructor(props)
     {
        super(props);

        var state = {};

        // Initalize requirements and input states
        var formErrors = new Map();
        props.formInputs.forEach( (data) =>
        {
            if(data.required === 'required')
            {
                formErrors.set(data.id, '-EV');   /* EV is empty value */
            }
            state[data.id] = data.value ? data.value : '';

            if(data.type !== 'submit')
            {
                this.fieldRefs.set(data.id, React.createRef());
            }
        });

        state['formErrors']     = formErrors;
        state['isFormValid']    = false;

        this.state = state;

        // Create reference so we can hold onto child
        this.submitBtn = React.createRef();
    }

    componentDidMount()
    {
      if(this.props.validateOnInit)
      {
        this.validateOnInit();
      }
    }


    // MARK: - onChange handlers
	/**
	  *	Handle a form field input value changing
	  *
	  *	@param	{JSON}	id, value, validation
	  */
	formInputOnChange = (change) =>
	{
        //console.log('\tForm.onChange()');
		// Update internal state
		this.setState({ [change.id]: change.value });

		// Validate field
		this.validateField(change.id, change.value, change.validation);

		// Update parent page of change in form state
		this.props.formOnChange(change, this.state.isFormValid);
	}


    // MARK: - Validation
	/**
	 * Validate form field
	 *
	 * @param   {string}    id            		Id of field
	 * @param 	{string} 	value 				Value in field to validate
	 * @param 	{JSON}		validation 			JSON object with validation algorithm return true/false
	 *				algorithm: email|range
	 *				params: [range: min,max]
	 *
	 * Return empty string if valid or error message if invalid
	 *
	 */
	validateField(id, value, validation)
	{
		var errorMessage = '';

    if(validation)
    {
  		switch(validation.algorithm)
  		{
  			case 'email':
  				errorMessage = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? '' : 'This email address is invalid';
  				break;
  			case 'range':
                  if(value.length < validation.min)
                  {
                      errorMessage = id + ' is too short.  Please enter at least ' + validation.min + ' characters';
                  }
                  else if(value.length > validation.max)
                  {
                      errorMessage = id + ' is too long.  Please enter at most ' + validation.max + ' characters';
                  }
  				break;
  			default:
  				break;
  		}
    }

		var formErrors = this.state.formErrors;
    formErrors.set(id, errorMessage);

		this.setState({ formErrors: formErrors });
		this.validateForm();
	}

	validateForm()
	{
    // Put errors into list on top of form
    if(this.props.showErrorList)
    {
        // Clear previous errors
        this.formErrors = [];
        this.state.formErrors.forEach( (error, id) =>
        {
            this.formErrors.push(<FormError key={id} error={error} /> )
        });
    }

    // Iterate error map to see if we have any
    var isValid = true;
    var errorItr = this.state.formErrors.values();
    let entry = errorItr.next();
    while(!entry.done)
    {
        if(entry.value.length > 0)
        {
            isValid = false;
            break;
        }
        entry = errorItr.next();
    }

		this.setState({ isFormValid: isValid });
    this.submitBtn.current.formStateChanged(isValid);
	}


  validateOnInit()
  {
    this.props.formInputs.forEach( (data) =>
    {
      this.formInputOnChange({ id: data.id, value: data.value, validation: data.validation });
    });
  }

    // MARK: - Helpers
	isFormValid = () =>
	{
		return this.state.isFormValid;
	}

    // Clear fields and errors
    reset()
    {
        console.log('\tForm.reset()');

        // Clear errors and reset field values
        var formErrors = new Map();
        this.props.formInputs.forEach( (data) =>
        {
            if(data.required === 'required')
            {
                formErrors.set(data.id, '-EV');   /* EV is empty value */
            }
            this.setState({ [data.id]: data.value ? data.value : '' });

            // Don't reset submit buttons value
            if(data.type !== 'submit')
            {
                this.fieldRefs.get(data.id).current.updateValue('');
            }
        });
        this.setState({ formErrors: formErrors });

        this.submitBtn.current.formStateChanged(false);
    }

	// MARK: - Render
    render()
    {
        console.log('\tForm.render()');

        return (
		<form action="#"
            data-is-form-valid={this.state.isFormValid}
            autoComplete="off"
            className="form-validated">

			<div className='formErrors'>
                {this.formErrors}
			</div>

            <div className={`form-group row ${!this.props.description ? 'hidden' : ''}`}>
				<div className="col-lg-12">
					<p>{this.props.description}</p>
				</div>
			</div>

            {this.props.formInputs.map(input =>
                <FormInput
                    ref={input.type === 'submit' ? this.submitBtn : this.fieldRefs.get(input.id)}
                    data={input}
                    key={input.id}
                    formInputOnChange={this.formInputOnChange}
                    isFormValid={this.isFormValid}
                    errorMessage={this.state.formErrors.get(input.id)}
                    value={this.state[input.id]}
                />)}
		</form>);
	}
}
