import ApiRequest    from '../api/request.js';
import FormLoader    from '../components/Form/FormLoader';
import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import { StateContext } from '../State';


/**
  *  State:
  *  	@param 	{string} 	email			Email address of user
  * 	@param 	{string}	password 		Password of user
  * 	@param 	{bool}		loginDisabled 	Login button disabled state
  * 	@param 	{bool}		isLoading		If loading is occuring on the page or not
  */
export default class Login extends React.Component
{
	static contextType = StateContext;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);

		this.state =
		{
			email: 			'',
			password: 		'',
			loginDisabled: 	true,
			isLoading: 		false,
		}
	}

	// MARK: - Event handlers
  /**
    * 	Handle enter key press
  	*	@param  {event} 	evt 	 On keypress event
  	*/
  	onKeyPress = (evt) =>
	{
		if(evt.key === "Enter")
		{
			this.login();
		}
  	}

  /**
    * 	Handle key press events in email/password fields
	*	@param  {event} 	evt 	 On change event
	*/
	textFieldOnChange = (evt) =>
	{
		this.setState({	[evt.target.id]: evt.target.value },
		function()
		{
			this.setState({ loginDisabled: (this.state.password.length > 5 && this.state.email.length > 4) ? false : true });
		});
	}

  /**
    * 	Handle login on click
	*	@param  {event} 	evt 	 On click event
	*/
	loginOnClick = (evt) =>
	{
		console.log('Login.loginOnClick()');
		evt.preventDefault();
		this.setState({ loginDisabled: true });
		this.login();
	}


	// MARK: - Helpers
	login = async () =>
	{
		if(!this.state.loginDisabled)
		{
			this.setState({ isLoading: true });

	        let params =
	        {
	            email:    	this.state.email,
	            password:   this.state.password
	        };

	        try
	        {
	            let response = await ApiRequest.sendRequest("post", params, "user/login");

				// Success
				if(response.data.success)
	            {
					// Save cookie
					this.props.cookies.set('token', response.data.token, { path: '/' })
					this.props.cookies.set('user', response.data.user, { path: '/' })

					// Update global state, idk if needed at this point
					const [{ login }, dispatch] = this.context;
					dispatch(
					{
						type: 'login',
						newUserState: { user: response.data.user }
					});

	            }
				// Failed
	            else
	            {
					this.setState(
					{
						loginDisabled: 	false,
						isLoading: 		false
					});
	                this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
	            }
	        }
	        catch(err)
	        {
	            this.setState(
				{
					isLoading: 		false,
					loginDisabled:	false
				});
	            this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
	        }
		}
	}


	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
    {
        // Only render if button status changed
        return (this.state.loginDisabled !== nextState.loginDisabled ||
					this.state.isLoading !== nextState.isLoading);
    }

	render()
	{
		//const [{ userState }, dispatch] = this.context; // For global state
		console.log('Login.render()');
		return (
		<>
			{this.props.cookies.get('user') ? <Redirect to='/home' /> : ''}
			<FormLoader
				isLoading={this.state.isLoading}
			/>
			<form className="content-container form-group">
				<div className="form-group row">
					<div className="col-lg-12">
						<input type="text" className="form-control" id="email" name="email" autoComplete="on" placeholder="Enter email"
							maxLength="128" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-12">
						<input type="password" className="form-control" id="password" name="current-password" autoComplete="on" placeholder="Enter password"
							maxLength="128" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-5">
						<a className="btn-link" id="forgotPasswordBtn" href="reset">Forgot password?</a>
					</div>
					<div className="col-lg-3"></div>
					<div className="col-lg-4">
						<button type="submit" className="btn btn-primary btn-block" id="loginBtn" disabled={this.state.loginDisabled} onClick={e => this.loginOnClick(e)}>Login</button>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-3"></div>
					<div className="col-lg-6">
						<a className="btn-link" id="registerBtn" href="register">Create an account</a>
					</div>
					<div className="col-lg-3"></div>
				</div>
			</form>
		</>
		);
	}
}

/*
<div class="form-group row">
	<div class="col-lg-4">
		<div class="g-recaptcha-inner">
			<div class="g-recaptcha" data-sitekey=""></div>
		</div>
	</div>
</div>
*/
