import React from 'react';

/**
  * Form that has validation on fields
  *
  *	@param 	{string} 	icon 		Themify classname for icon to display
  * @param 	{string}	title 		Title of feature
  * @param 	{string}	description Description of feature
  *
  */
const FeatureItem = (props) =>
{
    return (
        <div className="list text-left">
            <div className="icon icon-bg-md">
                <span className={`bg-color ${props.icon}`}/>
            </div>
            <div className="text">
                <h6>{props.title}</h6>
                <p>{props.description}</p>
            </div>
        </div>);
}
export default FeatureItem;
