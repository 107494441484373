import { Layout } from '../components/Layout';
import FeatureItem from '../components/Showcase/FeatureItem';
import ImageCarousel from '../components/Showcase/ImageCarousel';
import React from 'react'


function importAll(path)
{
    return path.keys().map(path);
}


/**
  * State:
  *     @param  {JSON}      projects        Contains all projects and array of features for each project
  *     @param  {string}    activeProject   Actively selected project title (index into projects map)
  */
export default class About extends React.Component
{
    // MARK: - Constructor
    constructor(props)
    {
        console.log('About()');
        super(props);
        this.state =
        {
            projects:
            {
                'MoonTrade':
                {
                    'features':
                    [
                        {
                            title: 'Blockchain Integration',
                            description: 'Integrated with Bitcoin, Ethereum, and Ripple blockchains to allow users to manage crypto currency wallets',
                            icon: 'ti-link'
                        },
                        {
                            title: 'Real-time Notifications',
                            description: 'Instant notifications via email, on-site popup, and SMS to users',
                            icon: 'ti-email'
                        },
                        {
                            title: 'Real-time Chat',
                            description: 'Chat with other users on the website in real-time with background checking for new messages',
                            icon: 'ti-comments'
                        },
                        {
                            title: 'Automated Escrow System',
                            description: 'Users can buy/sell crypto currencies to each other with an automated escrow system to keep their valuables safe',
                            icon: 'ti-rocket'
                        },
                        {
                            title: 'Two-Factor Authentication',
                            description: 'Accounts are protected by two-factor authentication via the Authy app or code sent via SMS message',
                            icon: 'ti-id-badge'
                        }
                    ],
                    images: importAll(require.context('../assets/MoonTrade/', false, /\.(png|jpe?g|svg)$/)),
                    type: 'iPad'
                },
                'Plunge Seaside':
                {
                    'features':
                    [
                        {
                            title: 'Third-party API Integration',
                            description: 'Integrated with existing third party APIs to extend functionality on an existing system',
                            icon: 'ti-shine'
                        },
                        {
                            title: 'Scalablility',
                            description: 'Based on the size of the event the backend need to be scaled to handle the large user load which was accomplished via easily managable cloud instances',
                            icon: 'ti-stats-up'
                        },
                        {
                            title: 'Donation Processing',
                            description: 'Allowed users to process donations for charity events',
                            icon: 'ti-credit-card'
                        },
                        {
                            title: 'Real-time',
                            description: 'As staff processed donations at an event the other staff members devices needed to be updated to reflect the new totals in real-time',
                            icon: 'ti-bolt-alt'
                        }
                    ],
                    images: importAll(require.context('../assets/Plunge Seaside/', false, /\.(png|jpe?g|svg)$/)),
                    type: 'iPad'
                },
                /*'Hobbyist':
                {
                    'features':
                    [
                        {
                            title: 'Payment Integration (Braintree)',
                            description: 'Ability to process payments via credit card and PayPal while still being PCI compliant',
                            icon: 'ti-wallet'
                        },
                        {
                            title: 'In App Purchases (iOS)',
                            description: 'Ability to use Apples built in payment system to process payments in app',
                            icon: 'ti-apple'
                        },
                        {
                            title: 'Mockup to UI (Zeplin)',
                            description: 'Converted the user interface from a mockup to an identical iOS application',
                            icon: 'ti-image'
                        },
                        {
                            title: 'Companion Website (admin panel)',
                            description: 'Created frontend website for administrators to use to manage settings that reflected in the app',
                            icon: 'ti-server'
                        }
                    ],
                    images: importAll(require.context('../assets/Hobbyist/', false, /\.(png|jpe?g|svg)$/)),
                    type: 'iPhone'
                },*/
                'Its Lit':
                {
                    'features':
                    [
                        {
                            title: 'Location Services',
                            description: 'Integrated with iOS built in location services to track users and notify them of locations of interest',
                            icon: 'ti-location-pin'
                        },
                        {
                            title: 'Geofencing',
                            description: 'When users reached within a certain amount of yard from a location of interest they received a notification',
                            icon: 'ti-location-arrow'
                        },
                        {
                            title: 'Google Maps API',
                            description: 'Using Google Maps APIs to provide users with the ability to map a route from their location to the location of interest',
                            icon: 'ti-google'
                        },
                        {
                            title: 'Apple Maps API',
                            description: 'Similar to the Google Maps integration but giving the user the ability to choose the map application they prefer',
                            icon: 'ti-apple'
                        }
                    ],
                    images: importAll(require.context('../assets/Its Lit/', false, /\.(png|jpe?g|svg)$/)),
                    type: 'iPhone'
                },
                'No Junk':
                {
                    'features':
                    [
                        {
                            title: 'Payment Integration (Braintree)',
                            description: 'Allowed students to process payments for their lunch meals with credit/debit card',
                            icon: 'ti-money'
                        },
                        {
                            title: 'QR Codes',
                            description: 'Each order has a unique QR code that lunch staff would scan on the students phone to match up their order',
                            icon: 'ti-camera'
                        },
                        {
                            title: 'Order Management',
                            description: 'Lunch staff has the ability to view orders in real-time as they are placed and fullfill them and modify their status to complete or canceled',
                            icon: 'ti-bar-chart'
                        },
                        {
                            title: 'Item Management',
                            description: 'Adminstrators could manage the items and prices of those items in the backend and the app reflects those updates in real-time',
                            icon: 'ti-bar-chart-alt'
                        }
                    ],
                    images: importAll(require.context('../assets/No Junk/', false, /\.(png|jpe?g|svg)$/)),
                    type: 'iPhone'
                }
            },
            activeProject: 'MoonTrade'
        }
    }

    // MARK: - onClick handlers
    projectTabOnClick(projectId)
    {
        console.log(projectId);
        this.setState({ activeProject: projectId });
    }


    // MARK: - Render
    render()
    {
        console.log("About.render()");

        return(
            <Layout>
                <div className="header-text">
                    <h2 className="title text-light">Why Choose Us?</h2>
                    <p className="sub-title">Checkout these satisfied clients' projects!</p>
                </div>

                <div className="text-center">
                    <ul className="tab-list nav">
                        {Object.keys(this.state.projects).map((project, i) =>
                            <li className={`tab ${this.state.activeProject === project ? 'active' : ''}`}
                                key={project}
                                id={this.props.icon}
                                onClick={e => this.projectTabOnClick(project)}>{project}
                            </li>
                        )}
                    </ul>
                </div>
                <div className="space"/>
                <div className="side-by-side">
                    <div className="col-sm-6 text-center">

                        <ImageCarousel
                            id='ImageCarousel'
                            images={this.state.projects[this.state.activeProject].images}
                            className={`product-image-${this.state.projects[this.state.activeProject].type}`}
                        />
                    </div>

                    <div className="col-sm-6">
                        {this.state.projects[this.state.activeProject].features.map( (feature, i) =>
                            <FeatureItem
                                key={i}
                                icon={feature.icon}
                                title={feature.title}
                                description={feature.description}
                            />
                        )}
                    </div>
                </div>
            </Layout>
        );
    }

    /*
    <img
        className={`product-image-${this.state.projects[this.state.activeProject].type}`}
        src={this.state.projects[this.state.activeProject].images[0]}
    />
    */
}
