import React from 'react';

/**
  * Form that has validation on fields
  *
  *	@param 	{string} 	id 			ID of carousel
  * @param 	{string}	imageSrc	URL of image
  * @param 	{bool}		isShowing 	If modal is showing or hidden
  * @param 	{function}	onClose		Call back to parent when we close modal
  *
  */
export default class ImageCarouselModal extends React.Component
{
	// MARK: - onClick events
	closeOnClick(value)
	{
		this.props.onClose();
	}


	// MARK: - Render
	render()
	{
		return (
			<div id={this.props.id} className={`carousel-image-modal ${this.props.isShowing ? 'carousel-image-modal-showing' : ''}`}>
				<span
					className="carousel-image-modal-close"
					onClick={e => this.closeOnClick(e.target.value)}>&times;</span>
				<img src={this.props.imageSrc} alt="modal-img" className="carousel-image-modal-content"/>
				<div id="carousel-image-modal-content-caption"></div>
			</div>);
	}
}
