import React 	from 'react';
import Loader   from 'react-spinners/ClimbingBoxLoader';
import { css }	from '@emotion/core';

const override = css`
    position:   absolute;
    left:       50%;
    top:        25%;
    z-index:    1000;
    width:      150px;
    height:     150px;
    margin:     -75px 0 0 -75px;
    background-color:   #777777;
    opacity:            0.5;
    border-radius:      10px;
`;

/**
  * Form that has validation on fields
  *
  * Props:
  *		@param {bool}	isLoading 	To display or not
  *
  */
 export default class FormLoader extends React.Component
 {
	// MARK: - Render
    shouldComponentUpdate(nextProps, nextState)
    {
        // Only render if button status changed
        return (this.props.isLoading !== nextProps.isLoading);
    }

    render()
    {
        console.log('\tFormLoader.render()');

        return (
			<Loader
				css={override}
				sizeUnit={"px"}
				size={25}
				color={'#FFF'}
				loading={this.props.isLoading}
			/>
		);
	}
}
