import React from 'react';
import 'rc-time-picker/assets/index.css';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'rc-time-picker';
import DatePicker from 'react-datepicker';
import Moment from 'moment';
const format = 'h:mm a';
const now = Moment().hour(0).minute(0);

function Requirement(props)
{
    var className = 'requirement' + (props.isHidden === true ? '' : '-showing');
    return (<div className={className}>{props.text}</div>);
}

/**
  * Input field for validated form
  *
  *     @param  {function}    isFormValid         Callback to form so we can check if entire form is valid
  *  	@param 	{function} 	  formInputOnChange   Callback when a form value changes
  *     @param  {bool}        errorMessage        Indicates if form field is in error state (empty if not) otherwise holds message
  *		@param	{JSON}	     data                 JSON object representing form input
  * 		label: 		Label text to show above input
  * 		id:			ID of inputs so it can be accessed by parent later
  * 		type: 		Type of input (text/password/email/submit)
  * 		element: 	The element type (input/textarea/select)
        options:   Only available for element of type select
  *         placeholder: Placeholder text to display {default=""}
  *         value:       Value
  *         onClick:     Onclick javascript handler
  *         required:    Required to submit form HTML5 validation (required) (default="")
  * 	   validation 	JSON object with validation algorithm return true/false
  *				algorithm: email|range
  *				params: [range: min,max]
  *
  * State:
  *     @param  {bool}    showRequiredDiv       If the field is in focus and error
  *     @param  {bool}    isInFocus             If the field is in focus or not
  *     @param  {bool}    isDisabled            If the field is disabled or not
  *     @param  {string}  value                 The value in the field (user input)
  *
 */
 export default class FormInput extends React.Component
 {
   _startDate = new Date();

     // MARK: - Constructor
     constructor(props)
     {
         super(props);

         this.state =
         {
             showRequiredDiv:   false,
             isInFocus:         false,
             isDisabled:        props.data.disabled,
             value:             props.value
         };
     }

    // MARK: - Event handlers
    // Update parent
    onChange(evt)
    {
        let value = (this.props.data.type === 'file' ? evt.target.files[0] : (evt.target ? evt.target.value : evt));
        this.setState({ value: value });

        var change =
        {
            id:         this.props.data.id,
            value:      value,
            validation: this.props.data.validation
        };

        // Notify form (parent)
        this.props.formInputOnChange(change);
    }

    /**
      *     Form will call this back after it validates itself.
      *     @param  {bool}  isValid     If the parent/form is valid or not
      */
    formStateChanged(isValid)
    {
        //console.log('\t\tFormInput(' + this.props.data.id + ').formStateChanged(isValid=' + isValid + ')');

        // If we are a submit button we will enable/disable ourselves
        if(this.props.data.type && this.props.data.type === 'submit')
        {
            this.setState({ isDisabled: !isValid });
        }
    }

    onFocus = () =>
    {
        //console.log('\t\tFormInput(' + this.props.data.id + ').onFocus()');
        this.setState({ isInFocus: true });
    }
    onFocusOut = () =>
    {
        //console.log('\t\tFormInput(' + this.props.data.id + ').onFocusOut()');
        this.setState({ showRequiredDiv: false, isInFocus: false });
    }

    // MARK: - Helpers
    updateValue(value)
    {
        this.setState({ value: value });
    }

	// MARK: - Render
    shouldComponentUpdate(nextProps, nextState)
    {
        // Only render if value changed, disabled state changed, or requirement div hidden/shown or focus changed or form is resetting
        return (this.state.value !== nextState.value ||
                this.state.isDisabled !== nextState.isDisabled ||
                this.props.errorMessage !== nextProps.errorMessage ||
                this.state.isInFocus !== nextState.isInFocus);
    }

    render()
    {
        //console.log('\t\tFormInput(' + this.props.data.id + ').render()');

        var label = (this.props.data.label ? <label>{this.props.data.label}</label> : '');
        var requirement = (this.props.data.validation && this.props.data.validation.requirement ? this.props.data.validation.requirement : '')

        var requirementDivIsHidden = true;

        // Class name
        var className   = (this.props.data.class ? this.props.data.class : '');
        if(this.props.errorMessage === '' || this.props.errorMessage === undefined)
        {
            // No error and value input so show valid
            if(this.state.value.length > 0)
            {
                className += 'valid';
            }
        }
        else
        {
            // Show requirement div  if error message
            if(this.props.errorMessage !== '-EV')
            {
                // If field not in focus show it as pink
                if(!this.state.isInFocus)
                {
                    className += 'invalid';
                }
                // Otherwise show requirement text
                else
                {
                    requirementDivIsHidden = false;
                }
            }
        }

        // Required
        //var required    = (this.props.data.required ? this.props.data.required : '');

        var onClick = (e) =>
        {
            if(this.props.data.onClick)
            {
                //console.log('\t\tFormInput(' + this.props.data.id + ').onClick()');
                this.props.data.onClick(e);
            }
        };

        const ElementTag = `${this.props.data.element}`;
        const formInput = ((this.props.data.element === 'select' &&
          <ElementTag
            type={this.props.data.type}
            id={this.props.data.id}
            value={this.state.value}
            onChange={e => this.onChange(e)}
            onClick={e => onClick(e)}
            key={this.props.data.id}
            className={className}
            disabled={this.state.isDisabled}
            required={this.props.data.required}
            onFocus={this.onFocus}
            onBlur={this.onFocusOut}>
            {this.props.data.options.map(entry =>
              <option key={entry.value} value={entry.value}>{entry.text}</option>
            )}
          </ElementTag>)
        ||
        (this.props.data.element === 'time' &&
        <TimePicker
          showSecond={false}
          defaultValue={now}
          className=""
          onChange={e => this.onChange(e)}
          format={format}
          use12Hours
        />)
        ||
        (this.props.data.element === 'datetime' &&
        <DatePicker
          selected={(this.state.value ? this.state.value : this._startDate)}
          onChange={e => this.onChange(e)}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={15}
          timeCaption="time"
          dateFormat="MMMM d, yyyy h:mm aa"
          required={this.props.data.required}
        />)
        ||
        (<ElementTag
          type={this.props.data.type}
          id={this.props.data.id}
          key={this.props.data.id}
          value={this.props.data.type === 'file' ? this.state.value.fileName : this.state.value}
          placeholder={this.props.data.placeholder}
          onChange={e => this.onChange(e)}
          onClick={e => onClick(e)}
          className={className}
          required={this.props.data.required}
          disabled={this.state.isDisabled}
          onFocus={this.onFocus}
          onBlur={this.onFocusOut}
      	/>));


        return (
    		<>
    			{label}

    			{formInput}
          <Requirement isHidden={requirementDivIsHidden}
              text={requirement}
          />
    		</>);
	}
}
