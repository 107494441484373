import ApiRequest    from '../../api/request.js';
import FormLoader    from '../../components/Form/FormLoader';
import FormValidated    from '../../components/Form/FormValidated';
import Table    from '../../components/Table';
import React 		 from 'react';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TransitionModal from '../../components/TransitionModal';


/**
  *  State:
  *  	@param 	{bool} 		isLoading		To display activity indicator or not
	* 	@param  {[User]}	users 			Array of registered users
	* 	@param 	{[Authorization]}	authorizations 	Array of authorization types users can have
	* 	@param 	{bool}		displayModal 	To display the modal window or not
	* 	@param 	{User}		selectedUser 	User selected to edit
	* 	@param 	{[JSON]}	modifyUserFormInputs 	Form inputs for the modification form
	*
  */
export default class Users extends React.Component
{
	_isMounted = false;
	_tableHeaders = [
			{id: 'username', 					numeric: false,	label: 'User'},
			{id: 'photo', 						numeric: false,	label: 'Photo'},
			{id: 'email', 						numeric: false,	label: 'Email'},
			{id: 'phone', 						numeric: false,	label: 'Phone'},
			{id: 'createdOn', 				numeric: false,	label: 'Registration Date'}
	];
	_form = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('Users()');
		super(props);
		this.state =
		{
			isLoading: false,
			users: null,
			authorizations: null,
			displayModal: false,
			selectedUser: null,

			class: 'User',

			modifyUserFormInputs: [
				{label: 'Photo',   						id: 'photo',    			element: 'input',   type: 'file' },
				{label: 'Full name',   				id: 'fullName',    		element: 'input',   type: 'text',   validation: {requirement: 'Your name must be at least 2 to 32 characters.', algorithm: 'range', 'min': 2, 'max': 32},  required: 'required', value: ''},
				{label: '',            				id: 'submit',       	element: 'input',   type: 'submit',      value: 'Update User', onClick: this.handleModifyUserFormSubmit, class: 'btn-block  .border .border--white', disabled: true}
			],
		}

		this._form = React.createRef();
	}

	componentDidMount()
	{
		console.log('Users.componentDidMount()');
		this._isMounted = true;
		this.loadUsersAndAuthorizations();
	}

  /**
  	Load users
	*/
	loadUsersAndAuthorizations = async () =>
	{
		console.log('Users.loadUsersAndAuthorizations()');
		this.setState({ isLoading: true });

		var params = { class: this.state.class };

		try
		{
			// Get users
			var response = await ApiRequest.sendRequest("post", params, "api/query", this.props.cookies.get('token'));
			console.log(response);
			if(response.data.error !== null)
			{
				this.setState(
				{
					isLoading: 		false
				});
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
			}
			var users = response.data.results;

			// Get authorizations
			params = { class:"Authorization" };
			response = await ApiRequest.sendRequest("post", params, "api/query", this.props.cookies.get('token'));
			if(response.data.error !== null)
			{
				this.setState(
				{
					isLoading: 		false
				});
				this.props.showAlert(true, 'Un-oh', response.data.error.toString(), 'danger');
			}

			// Build option list
			var modifyUserFormInputs = this.state.modifyUserFormInputs;
			var options = response.data.results.map( (authorization) =>
			{
				return {text: authorization.type, value: authorization.type};
			});
			modifyUserFormInputs[1].options = options;

			this.setState({
				isLoading: false,
				users: users,
				authorizations: response.data.results,
				modifyUserFormInputs: modifyUserFormInputs
			});
		}
		catch(err)
		{
			this.setState(
			{
				isLoading: 		false,
			});
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}

		modalOnClose = () =>
		{
			this.setState({ displayModal: false });
		}
		modifyUser = async () =>
		{
			console.log('Users.modifyUser()');
			this.setState({ displayModal: true });
		}
		handleModifyUserFormSubmit = async() =>
		{
			console.log(this.state);
			console.log('Users.handleModifyUserFormSubmit()');
			this.setState({ isLoading: true });

	    const formData = new FormData()
	   	formData.append('photo', this.state.photo);
			formData.set('name', this.state.fullName);
			formData.set('userId', this.state.selectedUser._id);

			console.log(formData.get('photo'));

			try
			{
				let response = await ApiRequest.sendRequest("post", formData, "admin/modify-user", this.props.cookies.get('token'), 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');

				// Success
				if(response.data.error === null)
				{
					// Update selected user
					var modifiedSelectedUser = this.state.selectedUser;
					modifiedSelectedUser.name = this.state.fullName;
					//modifiedSelectedUser.description = this.state.description;
				//	modifiedSelectedUser.authorization.type = this.state.authorization;
					modifiedSelectedUser.photo = response.data.photoURL;

					// Update table data
					var modifiedUsers = this.state.users;
					var selectedUserIndex = modifiedUsers.map((user) => { return user['_id']; }).indexOf(this.state.selectedUser._id);
					modifiedUsers[selectedUserIndex].name = this.state.fullName;
					//modifiedUsers[selectedUserIndex].description = this.state.description;
					//modifiedUsers[selectedUserIndex].authorization.type = this.state.authorization;
					modifiedUsers[selectedUserIndex].photo = response.data.photoURL;

					this.setState({
						isLoading: false,
						displayModal: false,
						selectedUser: modifiedSelectedUser,
						user: modifiedUsers,
					});
					this.props.showAlert(true, 'Success', response.data.message, 'success');
					this._form.current.reset();
				}
				// Failed
				else
				{
					this.setState(
					{
						isLoading: 		false
					});
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				}
			}
			catch(err)
			{
				this.setState(
				{
					isLoading: 		false,
				});
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}

		rowSelectedButtons =
			<Tooltip title="Modify selected user">
				<IconButton onClick={() =>
					{
						this.modifyUser();
					}} aria-label="user">
					<GroupAddIcon />
				</IconButton>
			</Tooltip>;



	modifyUserForm()
	{
		return(
		<div align="left">
			<FormValidated
				ref={this._form}
				formInputs={this.state.modifyUserFormInputs}
				formOnChange={this.modifyUserFormOnChange}
				showErrorList={false}
				validateOnInit={true}
			/>
		</div>);
	}

	modifyUserFormOnChange = (change, isFormValid) =>
	{
		this.setState({ [change.id]: change.value });
	}

	tableRowOnSelect = (id) =>
	{
		var selectedUser = this.state.users.find(user => user._id === id);
		var modifyUserFormInputs = this.state.modifyUserFormInputs;
		//modifyUserFormInputs[1].value = selectedUser.authorization.type;
		modifyUserFormInputs[2].value = selectedUser.name;
		//modifyUserFormInputs[3].value = selectedUser.description;
		this.setState({ modifyUserFormInputs: modifyUserFormInputs, selectedUser: selectedUser });
	}

	// MARK: - Render
	render()
	{
		console.log('Users.render()');
		return (
		<>
			<FormLoader isLoading={this.state.isLoading}/>
			<Table
				data={this.state.users}
				headers={this._tableHeaders}
				rowSelectedButtons={this.rowSelectedButtons}
				selectAllEnabled={false}
				multiSelectEnabled={false}
				rowOnSelect={this.tableRowOnSelect}
				title='Users'
			/>
			<TransitionModal
				isOpen={this.state.displayModal}
				onClose={this.modalOnClose}
				title={`Modifying user ${(this.state.selectedUser ? this.state.selectedUser.username : '')}`}
				description='To modify this user you will need to fill in their name and an optional photo'
				modalContent={this.modifyUserForm()}
			/>
		</>
		);
	}
}
