import ApiRequest    from '../../api/request.js';
import FormLoader    from '../../components/Form/FormLoader';
import FormValidated    from '../../components/Form/FormValidated';
import React 		 from 'react';
import EnhancedGrid     from '../../components/Grid';
import NavList  	 from '../../components/Nav/NavList';

/**
  *  State:
  *  	@param 	{bool} 		isLoading		To display activity indicator or not
	* 	@param  {[User]}	dataSet 			Array of results
	*
  */
export default class CustomerHome extends React.Component
{
	_isMounted = false;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('CustomerHome()');
		super(props);
		this.state =
		{
			isLoading: false,
			dataSet: null,
			class: 'Item',
			nav:
			[
				{title: 'Paintings', 	icon: 'ti-dashboard'},
				{title: 'Sculptures',	icon: 'ti-server'},
				{title: 'Cats', 			icon: 'ti-user'},
			],
			active: 'Cats',
		}
	}

	componentDidMount()
	{
		console.log('CustomerHome.componentDidMount()');
		this._isMounted = true;
		this.loadData();
	}

  /**
  	Load data
	*/
	loadData = async () =>
	{
		console.log('CustomerHome.loadData()');
		this.setState({ isLoading: true });
		var params = { };

		try
		{
			// Get results
			var response = await ApiRequest.sendRequest("post", params, "item/list", this.props.cookies.get('token'));
			console.log(response.data);
			if(response.data.error !== null)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
			}
			this.setState({
				isLoading: false,
				dataSet: response.data.results
			});
		}
		catch(err)
		{
		  this.setState({ isLoading: false });
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}


	// MARK: - Render
	render()
	{
		console.log('CustomerHome.render()');
		return (
		<>
			<FormLoader isLoading={this.state.isLoading}/>
			<div className="home">
				<div className="pane-holder">
					<div className="left-pane">
						<NavList
							items={this.state.nav}
							default='Cats'
							onChange={this.navOnChange}
							active={this.state.active}
						/>
					</div>

					<div className="center-pane">
						<EnhancedGrid spacing={12} dataSet={this.state.dataSet} />
					</div>
				</div>
			</div>
		</>
		);
	}
}
