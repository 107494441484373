import ApiRequest    from '../../api/request.js';
import FormValidated from '../../components/Form/FormValidated';
import FormLoader    from '../../components/Form/FormLoader';
import React 		 from 'react';


/**
  *  State:
  *  	@param 	{bool} 		isLoading		Form loading or not
  */
export default class ConfirmReset extends React.Component
{
	form = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log("ConfirmReset()");
		super(props);

		this.state =
		{
			isLoading: 	false,
			formInputs: [    {label: '',   id: 'password',    			element: 'input',   type: 'password',   validation: {requirement: 'Password must be at least 6 characters', algorithm: 'range', 'min': 6, 'max': 128},  required: 'required', placeholder: 'Enter password'},
                            {label: '',    id: 'confirm_password',     element: 'input',   type: 'password',   validation: {requirement: 'Password must be at least 6 characters', algorithm: 'range', 'min': 6, 'max': 128},  required: 'required', placeholder: 'Confirm password'},
                            {label: '',             id: 'submit',       element: 'input',   type: 'submit',      value: 'Update Password', onClick: this.handleFormSubmit, class: 'btn-block  .border .border--white', disabled: true}]
		}
		this.form = React.createRef();
	}

	// MARK: - Event handlers
	passwordFormOnChange = (change, isFormValid) =>
    {
        this.setState({ [change.id]: change.value });
    }

    // MARK: - Button handlers
    handleFormSubmit = async (event) =>
    {
        console.log('ConfirmReset.handleFormSubmit()');
        event.preventDefault();

		if(this.state.password !== this.state.confirm_password)
		{
			this.props.showAlert(true, 'Uh-oh', 'Your passwords to not match.', 'danger');
			return;
		}

        this.setState({ isLoading: true });

        let params =
        {
            token:      this.props.qs.token,
            user:    	this.props.qs.user,
            password:   this.state.password
        };

        try
        {
            let response = await ApiRequest.sendRequest("post", params, "user/confirm-reset");

            this.setState({ isLoading: false });
            if(response.data.success)
            {
                // Clear form fields
                this.form.current.reset();
                this.props.showAlert(true, 'Success!', response.data.message);
            }
            else
            {
                this.props.showAlert(true, 'Un-oh', response.data.message, 'danger');
            }
        }
        catch(err)
        {
            this.setState({ isLoading: false });
            this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
        }
    }


	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
    {
        return (this.state.isLoading !== nextState.isLoading);
    }

	render()
	{
		console.log('ConfirmReset.render()');
		return (
		<>
		<FormLoader
			isLoading={this.state.isLoading}
		/>
		<div className="content-container">
			<FormValidated
				ref={this.form}
				formInputs={this.state.formInputs}
				formOnChange={this.passwordFormOnChange}
				showErrorList={false}
				description='Type your new password below to finish updating your account.'
			/>
		</div>
		</>
		);
	}
}
