import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import About from './pages/About';
import ComingSoon from './pages/ComingSoon';
import Contact from './pages/Contact';
import Home from './pages/Home';
import CustomerHome from './pages/customer/Home';
import Login from './pages/Login';
import Logout from './pages/Logout';
import Register from './pages/Register';
import Reset from './pages/reset/Reset';
import { NoMatch } from './pages/NoMatch';
import { NavigationBar } from './components/Nav/NavigationBar';
import { withCookies } from 'react-cookie';
import AlertDismissible from './components/AlertDismissible';
import './css/App.css';
import './css/bootstrap.min.css';
import './css/themify-icons.css';

class App extends Component
{
    constructor(props)
    {
        console.log("App()");
        super(props);

        this.state =
        {
            alert:
            {
                isShowing:      false,
                header:         '',
                detail:         '',
                dismissText:    '',
            }
        }
    }


    /**
     * Alert box that can be dismissed
     *
     * @param   {bool}      show            To show/hide alert
     * @param 	{string} 	header 			Title text
     * @param 	{string}	detail 			Description text
     * @param 	{string}	dismissText 	Dismiss button text
     * @param   {string}    variant         Variant type { 'primary','secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark' }
     *
     */
    showAlert = (show,
                header = '',
                detail = '',
                variant = 'success',
                dismissText = 'Dismiss') =>
    {
        this.setState({ alert:
        {
            isShowing:      show,
            header:         header,
            detail:         detail,
            dismissText:    dismissText,
            variant:        variant
        }});
    }




    // MARK; - Render
    render()
    {
        console.log('App.render()');
        let alertDismissible;
        if(this.state.alert.isShowing)
        {
            alertDismissible = <AlertDismissible header={this.state.alert.header}
                   detail={this.state.alert.detail}
                   dismissText={this.state.alert.dismissText}
                   show={this.state.alert.isShowing}
                   variant={this.state.alert.variant}
                   dismissCb={this.showAlert}/>;
        }

        return (
            <div className="App">
            <>
                <Router>
                    <NavigationBar token={this.props.cookies.get('token')}/>
                    {alertDismissible}

                        <Switch>
                            <Route exact path="/" render={(props) =>
                                <About {...props}
                                    showAlert={this.showAlert}
                                    cookies={this.props.cookies}
                                />}
                            />
                            <Route path="/soon" render={(props) =>
                                <ComingSoon {...props}
                                    showAlert={this.showAlert}
                                />}
                            />
                            <Route path="/contact" render={(props) =>
                                <Contact {...props}
                                    showAlert={this.showAlert}
                                />}
                            />
                            <Route path="/home" render={(props) =>
                                <Home {...props}
                                    showAlert={this.showAlert}
                                    cookies={this.props.cookies}
                                />}
                            />
                            <Route path="/login" render={(props) =>
                                <Login {...props}
                                    showAlert={this.showAlert}
                                    cookies={this.props.cookies}
                                />}
                            />
                            <Route path="/logout" render={(props) =>
                                <Logout {...props}
                                    showAlert={this.showAlert}
                                    cookies={this.props.cookies}
                                />}
                            />
                            <Route path="/reset" render={(props) =>
                                <Reset {...props}
                                    showAlert={this.showAlert}
                                    cookies={this.props.cookies}
                                />}
                            />
                            <Route path="/register" render={(props) =>
                                <Register {...props}
                                    showAlert={this.showAlert}
                                    cookies={this.props.cookies}
                                />}
                            />
                            <Route component={NoMatch} />
                        </Switch>
                </Router>
            </>
            </div>
        );
    }
}

export default withCookies(App);
