import ApiRequest    from '../api/request.js';
import FormLoader    from '../components/Form/FormLoader';
import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import { StateContext } from '../State';


/**
  *  State:
  *  	@param 	{string} 	email			Email address of user
  * 	@param 	{string}	password 		Password of user
  * 	@param 	{bool}		submitDisabled 	Login button disabled state
  * 	@param 	{bool}		isLoading		If loading is occuring on the page or not
  */
export default class Register extends React.Component
{
	static contextType = StateContext;

	// MARK: - Constructor
	constructor(props)
	{
		super(props);

		this.state =
		{
			submitDisabled: 	true,
			isLoading: 		false,

			name: '',
			email: '',
			password: '',
			confirmPassword: '',
		}
	}

	// MARK: - Event handlers
  /**
    * 	Handle enter key press
  	*	@param  {event} 	evt 	 On keypress event
  	*/
  	onKeyPress = (evt) =>
	{
		if(evt.key === "Enter")
		{
			this.register();
		}
  	}

  /**
    * 	Handle key press events in email/password fields
	*	@param  {event} 	evt 	 On change event
	*/
	textFieldOnChange = (evt) =>
	{
		this.setState({	[evt.target.id]: evt.target.value },
		function()
		{
			this.setState({ submitDisabled: (this.state.password.length > 5 && this.state.confirmPassword.length > 5 && this.state.email.length > 4) ? false : true });
		});
	}

  /**
    * 	Handle form submit on click
	*	@param  {event} 	evt 	 On click event
	*/
	submitOnClick = (evt) =>
	{
		console.log('Register.loginOnClick()');
		evt.preventDefault();
		this.setState({ submitDisabled: true });
		this.register();
	}


	// MARK: - Helpers
	register = async () =>
	{
		if(!this.state.submitDisabled)
		{
			if(this.state.password !== this.state.confirmPassword)
			{
					this.props.showAlert(true, 'Un-oh', 'Passwords do not match', 'danger');
					return;
			}

			this.setState({ isLoading: true });

      let params =
      {
          name:    	this.state.name,
          password:   this.state.password,
					email: this.state.email,
      };

      try
      {
          let response = await ApiRequest.sendRequest("post", params, "user/register");

				// Success
				if(response.data.success)
        {
					// Save cookie
					this.props.cookies.set('token', response.data.token, { path: '/' });
					this.props.cookies.set('user', response.data.user, { path: '/' });

					this.setState({ submitDisabled: false, isLoading: false });
        }
				// Failed
        else
        {
					this.setState({ submitDisabled: false, isLoading: false });
	        this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
	    	}
	     }
	     catch(err)
	     {
				 	this.setState({ submitDisabled: false, isLoading: false });
					this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
				}
			}
	}


	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
    {
        // Only render if button status changed
        return (this.state.submitDisabled !== nextState.submitDisabled ||
					this.state.isLoading !== nextState.isLoading);
    }

	render()
	{
		//const [{ userState }, dispatch] = this.context; // For global state
		console.log('Register.render()');
		return (
		<>
			{this.props.cookies.get('user') ? <Redirect to='/home' /> : ''}
			<FormLoader
				isLoading={this.state.isLoading}
			/>
			<form className="content-container form-group">

				<div className="form-group row">
					<div className="col-lg-12">
						<input type="text" className="form-control" id="name" name="name" autoComplete="on" placeholder="First and last name"
							maxLength="64" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-12">
						<input type="text" className="form-control" id="email" name="email" autoComplete="on" placeholder="Enter email"
							maxLength="128" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-12">
						<input type="password" className="form-control" id="password" name="password" autoComplete="on" placeholder="Enter password"
							maxLength="128" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-12">
						<input type="password" className="form-control" id="confirmPassword" name="confirmPassword" autoComplete="on" placeholder="Confirm password"
							maxLength="128" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
					</div>
				</div>

				<div className="form-group row">
					<div className="col-lg-7">
						<a className="btn-link" id="forgotPasswordBtn" href="login">Alredy have an account?</a>
					</div>
					<div className="col-lg-1"></div>
					<div className="col-lg-4">
						<button type="submit" className="btn btn-primary btn-block" id="loginBtn" disabled={this.state.submitDisabled} onClick={e => this.submitOnClick(e)}>Register</button>
					</div>
				</div>
			</form>
		</>
		);
	}
}
