import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';



function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp)
{
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) =>
  {
    const order = cmp(a[0], b[0]);
    if (order !== 0)
    {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

function EnhancedTableHead(props)
{
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {props.selectAllEnabled &&
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />}
        </TableCell>
        {props.headers.map((headCell, index) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={index === 0 ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={order}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected } = props;



  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {props.title}
        </Typography>
      )}

      {numSelected > 0 ? (props.rowSelectedButtons) : (props.noRowSelectedButtons)}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes =
{
  numSelected: PropTypes.number.isRequired,
};

class EnhancedTable extends React.Component
{
  constructor(props)
  {
    console.log("EnhancedTable()");
    super(props);

    this.state =
    {
      order: 'asc',
      orderBy: 'calories',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 5,
    };
  }


  getSelected = () =>
  {
    return this.state.selected;
  }

  handleRequestSort = (event, property) =>
  {
    const isDesc = this.state.orderBy === property && this.state.order === 'desc';
    this.setState({ order: (isDesc ? 'asc' : 'desc'), orderBy: property });
  };

  handleSelectAllClick = event =>
  {
    if (event.target.checked)
    {
      const newSelecteds = this.props.data.map(n => n.name);
      this.setState({ selected: newSelecteds });
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) =>
  {
    if(this.props.rowOnSelect)
    {
      this.props.rowOnSelect(id);
    }
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if(this.props.multiSelectEnabled)
    {
      if (selectedIndex === -1)
      {
        newSelected = newSelected.concat(this.state.selected, id);
      }
      else if (selectedIndex === 0)
      {
        newSelected = newSelected.concat(this.state.selected.slice(1));
      }
      else if (selectedIndex === this.state.selected.length - 1)
      {
        newSelected = newSelected.concat(this.state.selected.slice(0, -1));
      }
      else if (selectedIndex > 0)
      {
        newSelected = newSelected.concat(this.state.selected.slice(0, selectedIndex),
                                        this.state.selected.slice(selectedIndex + 1));
      }
    }
    else
    {
      newSelected = (selectedIndex === -1) ? [id] : [];
    }

    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, newPage) =>
  {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) =>
  {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  isSelected = (id) =>
  {
    return (this.state.selected.indexOf(id) !== -1);
  }

  extractFieldNameFromPointer = (iFieldName, iRow) =>
  {
    var visibleText = "";
    var fieldName = iFieldName;
    var fieldNameInPtr = "";
    var row = iRow;

    // Get total pointers in key
    let occurrences = (fieldName.match(/\./g) || []).length
    if(occurrences === 0)
    {
      return iRow[iFieldName];
    }

    // Iterate all pointers
    var splitIndex = -1;
    for(var i = 0; i < occurrences; i++)
    {
      splitIndex = fieldName.indexOf('.');
      try
      {
        // Extract pointer
        fieldNameInPtr  = fieldName.substring(splitIndex + 1);
        fieldName = fieldName.substring(0, splitIndex);

        // Slowly parse down the data
        if(fieldNameInPtr.indexOf('.') !== -1)
        {
          row = row[fieldName];
        }
        else
        {
          return row[fieldName][fieldNameInPtr];
        }
        fieldName = fieldNameInPtr;
      }
      catch(err)
      {
        //console.log("Pointer: " + fieldNameInPtr + " not found in property " + fieldName)
        return "";
      }
    }
  }




  render()
  {
  var emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, (this.props.data ? this.props.data.length : 0) - this.state.page * this.state.rowsPerPage);
  return (
    <div className='table-root'>
      <Paper className='table-inner'>
        <EnhancedTableToolbar
          numSelected={this.state.selected.length}
          noRowSelectedButtons={this.props.noRowSelectedButtons}
          rowSelectedButtons={this.props.rowSelectedButtons}
          title={this.props.title}
        />
        <div className='table-wrapper'>
          <Table
            className='table'
            aria-labelledby="tableTitle"
            size={this.state.dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              numSelected={this.state.selected.length}
              order={this.state.order}
              orderBy={this.state.orderBy}
              onSelectAllClick={this.handleSelectAllClick}
              onRequestSort={this.handleRequestSort}
              rowCount={this.props.data ? this.props.data.length : 0}
              headers={this.props.headers}
              selectAllEnabled={this.props.selectAllEnabled}
            />
            <TableBody>
              {this.props.data && stableSort(this.props.data, getSorting(this.state.order, this.state.orderBy))
                .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                .map((row, rowIndex) =>
                {
                  const isItemSelected = this.isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${rowIndex}`
                  var tableCells = null;
                  if(row)
                  {
                    tableCells = this.props.headers.map((header, headerIndex) =>
                    {
                      var visibleTextFieldName = header.id; // Header ID is the field in our data we want to display
                      var visibleText = "";

                      // Photo
                      if(header.id.indexOf('photo') !== -1)
                      {
                        let src = this.extractFieldNameFromPointer(visibleTextFieldName, row);
                        visibleText = (src ? <div className="image-cropper"><img src={src} alt='Profile image' className='profile-pic' /></div> : '')
                      }
                      else
                      {
                        visibleText = this.extractFieldNameFromPointer(visibleTextFieldName, row);
                      }
                      if(headerIndex === 0)
                      {
                        return <TableCell component="th" key={rowIndex + '-' + headerIndex} id={labelId} scope="row" padding="none">{visibleText}</TableCell>;
                      }
                      else
                      {
                        return <TableCell key={rowIndex + '-' + headerIndex} align="right">{visibleText}</TableCell>;
                      }
                    });
                  }


                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, row._id)}
                      role="checkbox"
                      aria-checked={this.isItemSelected}
                      tabIndex={-1}
                      key={row._id}
                      selected={this.isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      {tableCells}
                    </TableRow>
                  );
                })}
              {this.emptyRows > 0 && (
                <TableRow style={{ height: (this.state.dense ? 33 : 53) * this.emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={this.props.data ? this.props.data.length : 0}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
  }
}


export default EnhancedTable;
