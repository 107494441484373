import ApiRequest    from '../api/request.js';
import Config 	     from '../config';
import FormValidated from '../components/Form/FormValidated';
import FormLoader    from '../components/Form/FormLoader';
import React         from 'react';

/**
  *  State:
  *     @param 	{string} 	firstName 	        Users first name
  *     @param 	{string}	lastName 	        Users last name
  *     @param 	{string}	email  	            Users email address
  *     @param  {string}    message             User input message
  *     @param  {bool}      mailSent            Flag for if mail sent successfully
  *     @param  {Exception} error               Error if one occurred, null if not
  *     @param  {bool}      isLoading           Loading state
  */
export default class Contact extends React.Component
{
    // MARK: - Data fields
    form = null;

    // MARK: - Constructor
    constructor(props)
    {
        console.log('Contact()');
        super(props);
        this.state =
        {
            firstName:      '',
            lastName:       '',
            email:          '',
            message:        '',
            mailSent:       false,
            error:          null,
            formInputs:    [    {label: 'First Name',   id: 'firstName',    element: 'input',   type: 'text',   validation: {requirement: 'Your name must be at least 2 to 32 characters.', algorithm: 'range', 'min': 2, 'max': 32},  required: 'required'},
                                {label: 'Last Name',    id: 'lastName',     element: 'input',   type: 'text',   validation: {requirement: 'Your name must be at least 2 to 32 characters.', algorithm: 'range', 'min': 2, 'max': 32},  required: 'required'},
                                {label: 'Email',        id: 'email',        element: 'input',   type: 'email',  validation: {requirement: 'This is not a valid email address.', algorithm: 'email'},  required: 'required'},
                                {label: 'Message',      id: 'message',      element: 'textarea', validation: {requirement: 'This message is either too long or too short, it should be easy to tell which', algorithm: 'range', 'min': 12, 'max': 1024}, required: 'required', placeholder: 'Tell me about the project you have in mind'},
                                {label: '',             id: 'submit',       element: 'input',   type: 'submit',      value: 'Send', onClick: this.handleFormSubmit, class: 'btn-block  .border .border--white', disabled: true}
                            ],
            isLoading:      false
        };

        this.form = React.createRef();
    }

    // MARK: - onChange handlers
    contactFormOnChange = (change, isFormValid) =>
    {
        this.setState({ [change.id]: change.value });
    }

    // MARK: - Button handlers
    handleFormSubmit = async (event) =>
    {
        console.log('Contact.handleFormSubmit()');
        event.preventDefault();

        this.setState({ isLoading: true });

        let params =
        {
            to:         Config.CONTACT_EMAIL,
            subject:    Config.CONTACT_SUBJECT,
            body:       this.state.firstName + " " + this.state.lastName + "\n" + this.state.email + "\n" + this.state.message
        };

        try
        {
            let response = await ApiRequest.sendRequest("post", params, "email/send");

            this.setState({ isLoading: false });
            if(response.data.success)
            {
                // Clear form fields
                this.form.current.reset();
                this.props.showAlert(true, 'Email sent!', 'I will get back to you about this project idea soon.');
            }
            else
            {
                this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.', 'danger');
            }
        }
        catch(err)
        {
            this.setState({ isLoading: false });
            this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
        }
    }




    // MARK: - Render
    render()
    {
        console.log('Contact.render()');

        return(
            <>
                <h2 className="title text-light">Get In Touch</h2>
                <div className="content-container">
                    <FormLoader
                        isLoading={this.state.isLoading}
                    />
                    <div align="left">
                        <FormValidated
                            ref={this.form}
                            formInputs={this.state.formInputs}
                            formOnChange={this.contactFormOnChange}
                            showErrorList={false}
                        />
                    </div>
                </div>
            </>
        );
    }


}
