import ApiRequest    from '../../api/request.js';
import FormLoader    from '../../components/Form/FormLoader';
import React 		 from 'react';


/**
  *  State:
  *  	@param 	{bool} 		isLoading		Form loading or not
  */
export default class InitiateReset extends React.Component
{
	// MARK: - Constructor
	constructor(props)
	{
		console.log("InitiateReset()");
		super(props);

		this.state =
		{
			isLoading: 		false,
			resetDisabled: 	true
		}
	}

	// MARK: - Event handlers
  /**
	* 	Handle enter key press
	*	@param  {event} 	evt 	 On keypress event
	*/
	onKeyPress = (evt) =>
	{
		if(evt.key === "Enter")
		{
			this.sendResetEmail();
		}
	}

  /**
	* 	Handle key press events in email/password fields
	*	@param  {event} 	evt 	 On change event
	*/
	textFieldOnChange = (evt) =>
	{
		this.setState({	[evt.target.id]: evt.target.value },
		function()
		{
			this.setState({ resetDisabled: this.state.email.length > 4 ? false : true });
		});
	}

  /**
	* 	Handle reset on click
	*	@param  {event} 	evt 	 On click event
	*/
	resetOnClick = (evt) =>
	{
		console.log('Initiate.resetOnClick()');
		evt.preventDefault();
		this.setState({ resetDisabled: true });
		this.sendResetEmail();
	}


  /**
    * 	Send email to user with password reset instructions
	*	@param  {event} evt 	Onclick event
	*/
	sendResetEmail = async (evt) =>
	{
		this.setState({ isLoading: true });

		let params =
		{
			email: this.state.email
		};

		try
		{
			let response = await ApiRequest.sendRequest("post", params, "user/reset");

			// Success
			if(response.data.success)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Success', response.data.message, 'success');
			}
			// Failed
			else
			{
				this.setState(
				{
					isLoading: 		false,
					resetDisabled: 	false
				});
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
			}
		}
		catch(err)
		{
			this.setState(
			{
				isLoading: 		false,
				resetDisabled: 	false
			});
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
		}
	}



	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
    {
        return (this.state.isLoading !== nextState.isLoading ||
					this.state.resetDisabled !== nextState.resetDisabled);
    }

	render()
	{
		console.log('InitiateReset.render()');
		return (
		<>
		<FormLoader
			isLoading={this.state.isLoading}
		/>
		<form className="content-container form-group reset">
			<div className="form-group row">
				<div className="col-lg-12">
					<p>Provide the email address associated with your account to receive instructions to reset your password.</p>
				</div>
			</div>

			<div className="form-group row">
				<div className="col-lg-12">
					<input type="text" className="form-control" id="email" name="email" autoComplete="on" placeholder="Enter email"
						maxLength="128" required="required" onChange={e => this.textFieldOnChange(e)} onKeyPress={e => this.onKeyPress(e)}/>
				</div>
			</div>

			<div className="form-group row">
				<div className="col-lg-4">
					<button type="submit" className="btn btn-primary btn-block" id="resetBtn" disabled={this.state.resetDisabled} onClick={e => this.resetOnClick(e)}>Reset</button>
				</div>
			</div>
		</form>
		</>
		);
	}
}
