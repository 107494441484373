import React from 'react';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

/**
  @param  {int}   spacing     Spacing between cells
*/
class EnhancedGrid extends React.Component
{
  constructor(props)
  {
    console.log("EnhancedTable()");
    super(props);

    this.state =
    {
      order: 'asc'
    };
  }

  render()
  {
    console.log('EnhancedGrid.render()');
    return (<Grid container className={`enhanced-grid ${this.props.className}`} spacing={12}>
      <Grid item xs={12}>
        <Grid container justify="center" spacing={this.props.spacing ? this.props.spacing : 2}>
          {this.props.dataSet && this.props.dataSet.map( (item) =>
          {
            return (
            <Paper className="item-cell" style={{ cursor: 'pointer' }}>
              <Grid item xs>
                <Typography variant="body2" gutterBottom>
                  <img src={item.photo} alt='Item image' className='item-image' />
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">
                  {item.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography variant="body2">${item.price}</Typography>
              </Grid>
            </Paper>);
          })}
        </Grid>
      </Grid>
    </Grid>);
  }
}

export default EnhancedGrid;
