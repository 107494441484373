import ApiRequest    from '../../api/request.js';
import FormLoader    from '../../components/Form/FormLoader';
import FormValidated    from '../../components/Form/FormValidated';
import Table    from '../../components/Table';
import React 		 from 'react';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import TransitionModal from '../../components/TransitionModal';


/**
  *  State:
  *  	@param 	{bool} 		isLoading		To display activity indicator or not
	* 	@param  {[User]}	dataSet 			Array of results
	* 	@param 	{bool}		displayModal 	To display the modal window or not
	* 	@param 	{Object}	selectedRow 	Row selected to edit
	* 	@param 	{[JSON]}	modifyFormInputs 	Form inputs for the modification form
	*
  */
export default class Items extends React.Component
{
	_isMounted = false;
	_tableHeaders = [
			{id: 'title', 						numeric: false,	label: 'Title'},
			{id: 'photo', 						numeric: false,	label: 'Photo'},
			{id: 'description', 			numeric: false,	label: 'Description'},
			{id: 'price', 						numeric: false,	label: 'Price'},
			{id: 'stock', 						numeric: false,	label: 'Stock'},
			{id: 'createdOn', 				numeric: false,	label: 'Date Created'}
	];
	_form = null;
	_addForm = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('Items()');
		super(props);
		this.state =
		{
			isLoading: false,
			dataSet: null,
			displayModal: false,
			displayAddModal: false,
			selectedRow: null,
			class: 'Item',

			modifyFormInputs: [
				{label: 'Title',   		id: 'title',    			element: 'input',   type: 'text',   validation: {requirement: 'Title must be between 2 and 64 characters.', algorithm: 'range', 'min': 2, 'max': 64},  required: 'required', value: ''},
				{label: 'Description',id: 'description',    element: 'input',   type: 'text',  validation: {requirement: 'Description must be between 2 and 512 characters.', algorithm: 'range', 'min': 2, 'max': 512},  required: 'required', value: ''},
				{label: 'Price',			id: 'price',    			element: 'input',   type: 'number',  validation: {requirement: 'Price must be between 1 and 10000', algorithm: 'range', 'min': 1, 'max': 5},  required: 'required', value: ''},
				{label: 'Stock',			id: 'stock',    			element: 'input',   type: 'number',  validation: {requirement: 'Stock must be between 1 and 10000', algorithm: 'range', 'min': 1, 'max': 5},  required: 'required', value: ''},
				{label: 'Photo',   		id: 'photo',    			element: 'input',   type: 'file' },
				{label: '',      			id: 'submit',       	element: 'input',   type: 'submit',      value: 'Update', onClick: this.handleModifyFormSubmit, class: 'btn-block  .border .border--white', disabled: true}
			],

			addFormInputs: [
				{label: 'Title',   		id: 'title',    			element: 'input',   type: 'text',   validation: {requirement: 'Title must be between 2 and 64 characters.', algorithm: 'range', 'min': 2, 'max': 64},  required: 'required', value: ''},
				{label: 'Description',id: 'description',    element: 'input',   type: 'text',  validation: {requirement: 'Description must be between 2 and 512 characters.', algorithm: 'range', 'min': 2, 'max': 512},  required: 'required', value: ''},
				{label: 'Price',			id: 'price',    			element: 'input',   type: 'number',  validation: {requirement: 'Price must be between 1 and 10000', algorithm: 'range', 'min': 1, 'max': 5},  required: 'required', value: ''},
				{label: 'Stock',			id: 'stock',    			element: 'input',   type: 'number',  validation: {requirement: 'Stock must be between 1 and 10000', algorithm: 'range', 'min': 1, 'max': 5},  required: 'required', value: ''},
				{label: 'Photo',   		id: 'photo', 					element: 'input',   type: 'file' },
				{label: '',      			id: 'submit',       	element: 'input',   type: 'submit',      value: 'Create', onClick: this.handleAddFormSubmit, class: 'btn-block  .border .border--white', disabled: true}
			],
		}

		this._form = React.createRef();
		this._addForm = React.createRef();
	}

	componentDidMount()
	{
		console.log('Items.componentDidMount()');
		this._isMounted = true;
		this.loadData();
	}

  /**
  	Load data
	*/
	loadData = async () =>
	{
		console.log('Items.loadData()');
		this.setState({ isLoading: true });

		var params = {};

		try
		{
			// Get results
			var response = await ApiRequest.sendRequest("post", params, "item/list", this.props.cookies.get('token'));
			console.log(response.data);
			if(response.data.error !== null)
			{
				this.setState(
				{
					isLoading: 		false
				});
				this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
			}
			this.setState({
				isLoading: false,
				dataSet: response.data.results
			});
		}
		catch(err)
		{
			this.setState(
			{
				isLoading: 		false,
			});
			this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}

		modalOnClose = () =>
		{
			this.setState({ displayModal: false, displayAddModal: false });
		}
		modifyRow = async () =>
		{
			console.log('Items.modifyRow()');
			this.setState({ displayModal: true });
		}
		addRow = async () =>
		{
			console.log('Items.addRow()');
			this.setState({ displayAddModal: true });
		}
		deleteRow = async () =>
		{
			console.log('Items.deleteRow()');

			this.setState({ isLoading: true });
			let params = { id: this.state.selectedRow._id };
			try
			{
				let response = await ApiRequest.sendRequest("post", params, "item/delete", this.props.cookies.get('token'));

				// Success
				if(response.data.error === null)
				{
					// Remove from table
					var modifiedData = this.state.dataSet;
					var selectedRowIndex = modifiedData.map( (data) => { return data['_id']; }).indexOf(this.state.selectedRow._id);
					modifiedData.splice(selectedRowIndex, 1);

					this.setState({ isLoading: false, dataSet: modifiedData });
					this.props.showAlert(true, 'Success', response.data.message, 'success');
				}
				// Failed
				else
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				}
			}
			catch(err)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
		handleModifyFormSubmit = async() =>
		{
			console.log('Items.handleModifyFormSubmit()');

			this.setState({ isLoading: true });
	    const formData = this.getFormData('M');

			try
			{
				let response = await ApiRequest.sendRequest("post", formData, "item/update", this.props.cookies.get('token'), 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');

				// Success
				if(response.data.error === null)
				{
					// Update selected row and table data
					this.updateFormDataAndState(response.data, 'M');

					this.props.showAlert(true, 'Success', response.data.message, 'success');
					this._form.current.reset();
				}
				// Failed
				else
				{
					this.setState({ isLoading: false });
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				}
			}
			catch(err)
			{
				this.setState({ isLoading: false });
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}
		handleAddFormSubmit = async() =>
		{
			console.log('Items.handleAddFormSubmit()');
			this.setState({ isLoading: true });
	    const formData = this.getFormData('A');

			try
			{
				let response = await ApiRequest.sendRequest("post", formData, "item/add", this.props.cookies.get('token'), 'multipart/form-data; boundary=----WebKitFormBoundary7MA4YWxkTrZu0gW');

				// Success
				if(response.data.error === null)
				{
					// Update selected row and table data
					this.updateFormDataAndState(response.data, 'A');

					this.props.showAlert(true, 'Success', response.data.message, 'success');
					this._addForm.current.reset();
				}
				// Failed
				else
				{
					this.setState(
					{
						isLoading: 		false
					});
					this.props.showAlert(true, 'Un-oh', response.data.error, 'danger');
				}
			}
			catch(err)
			{
				this.setState(
				{
					isLoading: 		false,
				});
				this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
			}
		}

		/** Iterate headers and put form data into container
			*  	@param 	{char} 		modifyOrAdd		M for modify or A for add
		*/
		getFormData = (modifyOrAdd) =>
		{
			// Iterate headers and put form data into container
			var headerKey = "";
	    const formData = new FormData();
			let inputs = (modifyOrAdd === 'M' ? this.state.modifyFormInputs : this.state.addFormInputs);
			for(var i = 0; i < inputs.length; i++)
			{
				headerKey = inputs[i].id;
				// Photo
				if(inputs[i].id === 'photo')
				{
					formData.append(headerKey, this.state[headerKey]);
				}
				else // Regular data
				{
					formData.set(headerKey, this.state[headerKey]);
				}
			}
			if(modifyOrAdd === 'M')
			{
				formData.set('id', this.state.selectedRow._id);
			}

			console.log(formData.get('title'));

			return formData;
		}

		/** Update selected row and table data with newly modified or added object
			*  	@param 	{JSON} 		responseData		JSON response from backend with newly created object
			*  	@param 	{char} 		modifyOrAdd			M for modify or A for add
		*/
		updateFormDataAndState = (responseData, modifyOrAdd) =>
		{
			console.log(responseData)
			// Update selected row and table data
			var modifiedselectedRow = this.state.selectedRow;
			var modifiedData = this.state.dataSet;
			var selectedRowIndex = modifiedData.map( (data) => { return data['_id']; }).indexOf(this.state.selectedRow._id);
			var headerKey = "";
			let inputs = (modifyOrAdd === 'M' ? this.state.modifyFormInputs : this.state.addFormInputs);
			for(var i = 0; i < inputs.length; i++)
			{
				headerKey = inputs[i].id;
				modifiedselectedRow[headerKey] = responseData.result[headerKey];
				modifiedData[selectedRowIndex][headerKey] = responseData.result[headerKey];
			}

			this.setState({
				isLoading: false,
				displayModal: false,
				selectedRow: modifiedselectedRow,
				dataSet: modifiedData,
			});
		}

		rowSelectedButtons =
			<>
			<Tooltip title="Modify selected item">
				<IconButton onClick={() =>
					{
						this.modifyRow();
					}} aria-label="row">
					<EditIcon />
				</IconButton>
			</Tooltip>
			<Tooltip title="Delete selected item">
				<IconButton onClick={() =>
					{
						this.deleteRow();
					}} aria-label="row">
					<DeleteIcon />
				</IconButton>
			</Tooltip>
			</>;

		noRowSelectedButtons =
			<Tooltip title="Add new item">
				<IconButton onClick={() =>
					{
						this.addRow();
					}} aria-label="row">
					<AddCircleOutlineIcon />
				</IconButton>
			</Tooltip>;


	/**
		Modify form
	*/
	modifyForm()
	{
		return(
		<div align="left">
			<FormValidated
				ref={this._form}
				formInputs={this.state.modifyFormInputs}
				formOnChange={this.modifyFormOnChange}
				showErrorList={false}
				validateOnInit={true}
			/>
		</div>);
	}

	modifyFormOnChange = (change, isFormValid) =>
	{
		this.setState({ [change.id]: change.value });
	}

	tableRowOnSelect = (id) =>
	{
		var selectedRow = this.state.dataSet.find(obj => obj._id === id);

		var modifyFormInputs = this.state.modifyFormInputs;
		modifyFormInputs[0].value = selectedRow.title;
		modifyFormInputs[1].value = selectedRow.description;
		modifyFormInputs[2].value = selectedRow.price;
		modifyFormInputs[3].value = selectedRow.stock;
		this.setState({ modifyFormInputs: modifyFormInputs, selectedRow: selectedRow });
	}

	/**
		Add form
	*/
	addForm()
	{
		return(
		<div align="left">
			<FormValidated
				ref={this._addForm}
				formInputs={this.state.addFormInputs}
				formOnChange={this.modifyFormOnChange}
				showErrorList={false}
				validateOnInit={true}
			/>
		</div>);
	}

	// MARK: - Render
	render()
	{
		console.log('Items.render()');
		return (
		<>
			<FormLoader isLoading={this.state.isLoading}/>
			<Table
				data={this.state.dataSet}
				headers={this._tableHeaders}
				rowSelectedButtons={this.rowSelectedButtons}
				noRowSelectedButtons={this.noRowSelectedButtons}
				selectAllEnabled={false}
				multiSelectEnabled={false}
				rowOnSelect={this.tableRowOnSelect}
				title='Users'
			/>
			<TransitionModal
				isOpen={this.state.displayModal}
				onClose={this.modalOnClose}
				title={`Modifying item: ${(this.state.selectedRow ? this.state.selectedRow.title : '')}`}
				description='To modify this item you will need to fill in title, description, stock, price, and an optional photo'
				modalContent={this.modifyForm()}
			/>
			<TransitionModal
				isOpen={this.state.displayAddModal}
				onClose={this.modalOnClose}
				title='Add new item'
				description='Title, description, price, and stock are required at minimum'
				modalContent={this.addForm()}
			/>
		</>
		);
	}
}
