import ApiRequest    from '../api/request.js';
import FormValidated from '../components/Form/FormValidated';
import FormLoader    from '../components/Form/FormLoader';
import React 		 from 'react';

import '../css/coming_soon.css';

/**
  *  State:
  *  	@param 	{bool} 		a		Definition
  */
export default class ComingSoon extends React.Component
{
	form = null;

	// MARK: - Constructor
	constructor(props)
	{
		console.log('ComingSoon()');
		super(props);
		this.state =
		{
			isLoading: 	false,
			formInputs: [    {label: '',        id: 'email',        element: 'input',   type: 'email',  placeholder: 'Email address', validation: {requirement: 'This is not a valid email address.', algorithm: 'email'},  required: 'required'},
                                {label: '',     id: 'submit',  element: 'input',   type: 'submit',      value: 'Subscribe', onClick: this.handleFormSubmit, class: 'btn-block  .border .border--white', disabled: true}]
		}
		this.form = React.createRef();
	}

	// MARK: - onChange handlers
    emailFormOnChange = (change, isFormValid) =>
    {
  	  this.setState({ [change.id]: change.value });
    }

    // MARK: - Button handlers
    handleFormSubmit = async (event) =>
    {
  	  console.log('ComingSoon.handleFormSubmit()');
  	  event.preventDefault();

  	  this.setState({ isLoading: true });

  	  let params =
  	  {
  		  email: this.state.email
  	  };

  	  try
  	  {
  		  let response = await ApiRequest.sendRequest("post", params, "guest/subscribe");

  		  this.setState({ isLoading: false });
  		  if(response.data.success)
  		  {
  			  // Clear form fields
  			  this.form.current.reset();
  			  this.props.showAlert(true, 'Subscribed!', 'We will email you when the website is ready!');
  		  }
  		  else
  		  {
  			  this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.', 'danger');
  		  }
  	  }
  	  catch(err)
  	  {
  		  this.setState({ isLoading: false });
  		  this.props.showAlert(true, 'Un-oh', 'An error has occurred, please try again or contact support.\nError: ' + err, 'danger');
  	  }
    }


	// MARK: - Render
	render()
	{
		console.log('ComingSoon.render()');
		return (
		<div className="coming-soon">
			<FormLoader
				isLoading={this.state.isLoading}
			/>
			<div className="content-container">
				<h2>We are launching soon</h2>
				<h2>Subscribe below to receive an email when the site is live!</h2>

				<div align="left">
					<FormValidated
						ref={this.form}
						formInputs={this.state.formInputs}
						formOnChange={this.emailFormOnChange}
						showErrorList={false}
					/>
				</div>
			</div>
		</div>
		);
	}
}
