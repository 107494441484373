import Graphs 		 from './admin/Graphs';
import Items 		 from './admin/Items';
import Orders 		 from './admin/Orders';
import Promotions 	 from './admin/Promotions';
import NavList  	 from '../components/Nav/NavList';
import React 		 from 'react';
import { Redirect }  from 'react-router-dom';
import Settings 	 from './admin/Settings';
import Users 		 from './admin/Users';


/**
  *  State:
  *  	@param 	{JSON} 		admin		Contains nav links and icons
  */
export default class Home extends React.Component
{
	// MARK: - Constructor
	constructor(props)
	{
		console.log('Home()');
		super(props);

		var active = sessionStorage.getItem('homepage');
		active = (active !== null ? active : 'Orders');
		this.state =
		{
			admin:
			{
				nav:
				[
					{title: 'Orders', 		icon: 'ti-dashboard'},
					{title: 'Items', 			icon: 'ti-server'},
					{title: 'Users', 			icon: 'ti-user'},
					{title: 'Graphs', 		icon: 'ti-bar-chart-alt'},
					{title: 'Settings', 	icon: 'ti-settings'},
				]
			},
			active: active
		}
	}

  /**
    * 	Handler
	*	@param  {String} 	link 	Link being navigated to
	*/
	navOnChange = (link) =>
	{
		console.log("Home.navOnChange(" + link + ")");
		sessionStorage.setItem('homepage', link);
		this.setState({ 'active': link });
	}


	// MARK: - Render
	shouldComponentUpdate(nextProps, nextState)
    {
        return (this.state.active !== nextState.active);
    }

	mainContent()
	{
		switch(this.state.active)
		{
			case 'Orders':
				return <Orders showAlert={this.props.showAlert} cookies={this.props.cookies}/>;
			case 'Items':
				return  <Items showAlert={this.props.showAlert} cookies={this.props.cookies}/>;
			case 'Users':
				return <Users showAlert={this.props.showAlert} cookies={this.props.cookies}/>;
			case 'Promotions':
				return <Promotions showAlert={this.props.showAlert} cookies={this.props.cookies}/>;
			case 'Graphs':
				return <Graphs showAlert={this.props.showAlert} cookies={this.props.cookies}/>;
			case 'Settings':
				return <Settings showAlert={this.props.showAlert} cookies={this.props.cookies}/>;
			default:
				return null;
		}
	}

	render()
	{
		const mainComponent = this.mainContent();
		console.log('Home.render()');
		return (
		<div className="home">
			{!this.props.cookies.get('user') ? <Redirect to='/login' /> : ''}
			<div className="pane-holder">
				<div className="left-pane">
					<NavList
						items={this.state.admin.nav}
						default='home'
						onChange={this.navOnChange}
						active={this.state.active}
					/>
				</div>

				<div className="center-pane">
					{mainComponent}
				</div>
			</div>
		</div>
		);
	}
}
