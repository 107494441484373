import React 		 from 'react';
import { Redirect }  from 'react-router-dom';


export default class Logout extends React.Component
{
	// MARK: - Constructor
	constructor(props)
	{
		console.log('Logout()');
		super(props);

		// Clear cookies
		props.cookies.remove('user');
		props.cookies.remove('token');
	}


	// MARK: - Render
	render()
	{
		console.log('Logout.render()');
		return (
			<Redirect to='/home' />
		);
	}

}
