import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import ImageCarouselModal from './ImageCarouselModal';

/**
  * Form that has validation on fields
  *
  *	@param 	{string} 	id 			ID of carousel
  * @param 	{string}	images 		Array of image sources
  * @param 	{string}	className 	CSS class
  * @param 	{int}		activeIndex Index of images selected
  *
  *	State:
  *
  *	@param 	{int}	activeIndex 	Actively selected image index
  */
export default class ImageCarousel extends React.Component
{
	// MARK: - Constructor
	constructor(props)
    {
        super(props);
        this.state =
        {
			activeIndex: 0,
			modalIsShowing: false
		};
	}


	// MARK: - onChange handlers
	carouselItemOnChange = (selectedIndex, e) =>
	{
		console.log("\tImageCarousel.carouselItemOnChange(" + selectedIndex + ")");
		this.setState({ activeIndex: selectedIndex });
	}

	// Show modal
	carouselImageOnClick = (e) =>
	{
		console.log("\tImageCarousel.carouselImageOnClick(" + e + ")");
		this.setState({ modalIsShowing: true });
	}

	// Close modal
	carouselImageModalOnClose = () =>
	{
		this.setState({ modalIsShowing: false });
	}


    // MARK: - Render
    render()
    {
		return (
		<>
			<div className={this.props.id}>
				<Carousel
					controls={this.props.images.length > 1 ? true : false}
					activeIndex={this.props.images.length > 1 ? this.state.activeIndex : 0}
					onSelect={this.carouselItemOnChange}
					interval={this.state.modalIsShowing ? 0 : 5000}
				>
					{this.props.images.map( (img, i) =>

						<Carousel.Item
							className="image-carousel-item"
							key={i}>

							<img
								key={i}
								className={this.props.className}
								src={img}
								alt="First slide"
								onClick={this.carouselImageOnClick}
							/>

						</Carousel.Item>

					)}
				</Carousel>
			</div>

			<ImageCarouselModal
				id={`${this.props.id}-modal`}
				imageSrc={this.props.images[this.state.activeIndex]}
				isShowing={this.state.modalIsShowing}
				onClose={this.carouselImageModalOnClose}
			/>
		</>);

		/*
		<Carousel.Caption>
			<h3>First slide label</h3>
			<p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
		</Carousel.Caption>
		*/
    }
}
