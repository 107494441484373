import React from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';


/**
 * Alert box that can be dismissed
 *
 * @param   {bool}      show            	To show/hide alert
 * @param 	{string} 	header 				Title text
 * @param 	{string}	detail 				Description text
 * @param 	{string}	dismissText 		Dismiss button text
 * @param 	{function}	dismissCb(false) 	Callback when message box dismissed
 * @param	{string}	variant 			Variant type { 'primary','secondary', 'success', 'danger', 'warning', 'info', 'light', 'dark' }
 *
 * State: 	{bool}		show 	If message is being displayed or not
 *
 */
 export default class AlertDismissible extends React.Component
 {
	// MARK: - Constructor
    constructor(props)
    {
        super(props);
		this.state =
        {
            show: props.show
        };
    }

	// Hide alert and notify parent via callback
	dismissCb = () =>
	{
		this.setState({ show: false });
		if(this.props.dismissCb)
		{
			this.props.dismissCb(false);
		}
	}

	// MARK: - Render
    render()
    {
		return (
		<>
		  <Alert className="centered-overlay" variant={this.props.variant} show={this.state.show}>
		    <Alert.Heading>{this.props.header}</Alert.Heading>
		    <p>
		     	{this.props.detail}
		    </p>
		    <hr />
		    <div className="d-flex justify-content-end">
		      <Button onClick={this.dismissCb} variant={`outline-${this.props.variant}`}>
		        	{this.props.dismissText}
		      </Button>
		    </div>
		  </Alert>
		</>);
	}
}
