import ApiRequest    from '../../api/request.js';
import FormValidated from '../../components/Form/FormValidated';
import FormLoader    from '../../components/Form/FormLoader';
import React 		 from 'react';


/**
  *  State:
  *  	@param 	{bool} 		a		Definition
  */
export default class Orders extends React.Component
{
	// MARK: - Constructor
	constructor(props)
	{
		console.log('Orders()');
		super(props);
		this.state =
		{
			a: ''
		}
	}

  /**
    * 	Handler
	*	@param  {event} evt 	On change event
	*/
	evtHandler = (evt) =>
	{

	}


	// MARK: - Render
	render()
	{
		console.log('Orders.render()');
		return (
		<>
			Orders
		</>
		);
	}
}
