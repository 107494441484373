import React from 'react';
import { Nav } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
  a, .navbar-brand, .navbar-nav .nav-link
  {

    &:hover
	{
      color: black;
    }

	span
	{
	 	padding-right: 5px;
	}

	inline-size: fit-content;
	padding-right: 18px;
  }

  .active
  {
	  color: #FFF;
	  background: #000;
	  border-radius: 10px;

	  &:hover
	  {
		  color: #FFF;
	  }
  }
`;

/**
  * Navigation list
  *
  *	@param 	{[{JSON}]} 	items 		title,icon
  * @param 	{String}	default 	The default page to treat as home link
  *
  *	State:
  */
 export default class NavList extends React.Component
 {
	// MARK: - Constructor
    constructor(props)
    {
		console.log("NavList()");
        super(props);

        this.state =
        {
            active: this.props.active
        };
    }


	// MARK: - Onchange handlers
	navOnChange = (link) =>
	{
        if(this.state.active !== link)
        {
            this.setState({ active: link });
            this.props.onChange(link);
        }
	}


	// MARK: - Render
    render()
    {
        console.log('NavList.render()');

        return (
			<Styles>

			<Nav
				defaultActiveKey={`/${this.props.default}`}
				className="flex-column"
				onSelect={e => this.navOnChange(e)}>
	            {this.props.items.map( (item, i) =>
					<Nav.Link
						key={i}
						eventKey={item.title}>
							<span className={item.icon}/>
							{item.title}
					</Nav.Link>
				)}
			</Nav>
		 	</Styles>);
	}
}
