import Config from '../../config';
import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import styled from 'styled-components';

const Styles = styled.div`
  .navbar {
    background-color: #222;
  }


  a, .navbar-brand, .navbar-nav .nav-link {
    color: #BBB;

    &:hover {
      color: white;
    }
  }
`;

/* <Nav.Item>
   <Nav.Link href="/">Home</Nav.Link>
 </Nav.Item>
 <Nav.Item>
   <Nav.Link href="/about">About</Nav.Link>
 </Nav.Item>*/

export const NavigationBar = (props) => (
  <Styles>
    <Navbar expand="lg" variant="dark">
      <Navbar.Brand className="text-white" href="/">{Config.SITE_NAME}</Navbar.Brand>

      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          <Nav.Item>
            <Nav.Link href="/">About</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav.Item>
          <Nav.Item>
            {props.token ? <Nav.Link href="/logout">Logout</Nav.Link> : <Nav.Link href="/login">Login</Nav.Link>}
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </Styles >
)
